<template>
    <div id="sc-settings">
        <h1>Settings</h1>
        <!-- <hr /> -->
        <!-- <b-button class="sc-button-primary" @click="quickbooksAuthUri">Authorization</b-button> -->
        <hr />
        <b-button class="sc-button-primary" @click="refreshAccessToken">Refresh Token</b-button>
        <hr />

        <!-- <b-button class="sc-button-primary" @click="printTicket">Print Ticket</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="createAllPasswords">Create All Passwords</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="createCustomerPassword">Create Customer Password</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="getQuickbooksAllProducts">Get All Products</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="getQuickbooksCustomer">Get Customer Info</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="getQuickbooksAllCustomers">Get All Customers</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="getInvoiceInfo">Get Invoice Info</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="createInvoice">Create Invoice</b-button> -->
        <!-- <b-button class="sc-button-primary" @click="getAllInvoices">Get All Invoice</b-button> -->
        
         <!-- Get QB Invoices -->
        <!-- <b-button class="sc-button-primary" @click="getQuickbooksInvoices">Get QB Invoices</b-button>
        <hr /> -->

        <!-- <b-card no-body id="sc-qrcodes-card">
            <b-tabs card>
                <b-tab title="Quantity">
                    <div id="sc-quantity-container">
                        <h2>QR Codes</h2>

                        <table>
                            <tr>
                                <th>Quantity</th>
                                <th>Created</th>
                                <th>QRCode</th>
                                <th>Action</th>
                            </tr>
                            <tr class="sc-quantity-qrcode-container" v-for="qrcode in qrcodesQuantity" :key="qrcode.id">
                                <td>{{ qrcode.content }}</td>
                                <td>{{ qrcode.createdAtFormatted }}</td>
                                <td class="sc-quantity-qrcode-placeholder"></td>
                                <td><b-button @click="onClickShowPrintQrcode(qrcode)">Print</b-button></td>
                            </tr>
                        </table>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card> -->

        <div id="sc-settings-footer">
            <span>SaltCheap POS - v {{ version }}</span>
            <span>© Copyright 2020 | Salt Cheap | All Rights Reserved</span>
        </div>

        <!-- Modal - Product -->
        <!-- <b-modal id="sc-modal-product-qrcode" ref="sc-modal-product-qrcode" title="New Product QRCode" centered hide-footer>
            <b-form-select v-model="selectedProduct" :options="productOptions"></b-form-select>
            <b-button class="mt-3" block @click="onClickCreateProductQRCode">Create</b-button>
        </b-modal> -->

        <!-- Modal - Quantity -->
        <b-modal id="sc-modal-quantity-qrcode" ref="sc-modal-quantity-qrcode" title="New Quantity QRCode" centered hide-footer>
            <b-form-input v-model="createQRQuantity" required placeholder="Enter Quantity"></b-form-input>
            <b-button class="mt-3" block @click="onClickCreateQuantityQRCode">Create</b-button>
        </b-modal>

        <!-- Modal - Print Qrcode -->
        <b-modal id="sc-modal-print-qrcode" ref="sc-modal-print-qrcode" title="Print QRCode" centered hide-footer>
            <vue-html2pdf :filename="printQrcodeQuantity" pdf-format="a4" pdf-orientation="portrait" :show-layout="true" :float-layout="false" :useCORS="true" :letterRendering="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="750" :pdf-quality="2" :manual-pagination="false" pdf-content-width="100%" ref="html2Pdf">
                <section style="width: 563px; height: 750px; display:flex; flex-direction: row; align-items:center; justify-content:center" slot="pdf-content">
                    <div id="sc-print-qrcode-placeholder"></div>
                    <div class="sc-info" style="display: flex; flex-direction: column; align-items: start; padding: 10px">
                        <span style="font-size: 16px; width: 120px; text-align: left"
                            ><b>Quantity: {{ printQrcodeQuantity }}</b></span
                        >
                    </div>
                </section>
            </vue-html2pdf>
            <b-button class="mt-3" block @click="onClickPrintQRCode">Print</b-button>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import APICalls from "./APICalls";
import moment from "moment";
import QrcodeAPICalls from "./../qrcode/APICalls";
import ProductAPICalls from "./../product/APICalls";
import QuickbooksAPICalls from "./../quickbooks/APICalls";
import qrcodeGenerator from "qrcode-generator";
import VueHtml2pdf from "vue-html2pdf";
import { quickbooks_product_endpoint, quickbooks_invoice_endpoint, quickbooks_get_all_customer_endpoint, quickbooks_create_token_endpoint, quickbooks_get_auth_uri_endpoint, quickbooks_get_customer_endpoint, quickbooks_refresh_token_endpoint } from "./../../constant/api-endpoints";

export default {
    name: "Settings",
    components: {
        VueHtml2pdf,
    },
    data() {
        return {
            createQRQuantity: null,
            productsList: [],
            productOptions: [],
            printQrcode: {},
            printQrcodeQuantity: "",
            selectedProduct: null,
            qrcodesProduct: [],
            qrcodesQuantity: [],
            version: "1.6.0",
            allInvoices: []
        };
    },
    methods: {
        generateQrcodes() {
            let typeNumber = 0;
            let errorCorrectionLevel = "L";
            setTimeout(() => {
                // For - Product
                // _.each(this.qrcodesProduct, (qrcode, i) => {
                //     let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                //     qr.addData(qrcode.content);
                //     qr.make();
                //     document.getElementsByClassName("sc-product-qrcode-placeholder")[i].innerHTML = qr.createImgTag();
                // });

                // For - Quantity
                _.each(this.qrcodesQuantity, (qrcode, i) => {
                    let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                    qr.addData(qrcode.content);
                    qr.make();
                    document.getElementsByClassName("sc-quantity-qrcode-placeholder")[i].innerHTML = qr.createImgTag();
                });
            });
        },
        onClickCreateProductQRCode() {
            console.log("Create Product QRCode");
            this.createProductQrcode();
        },
        onClickCreateQuantityQRCode() {
            console.log("Create Quantity QRCode");
            this.createQuantityQrcode();
        },
        onClickPrintQRCode() {
            this.$refs.html2Pdf.generatePdf();
        },
        onClickShowPrintQrcode(qrcode) {
            this.printQrcodeQuantity = "";
            this.$refs["sc-modal-print-qrcode"].show();

            setTimeout(() => {
                let contentJson = JSON.parse(qrcode.content);
                this.printQrcodeQuantity = contentJson.quantity;

                // Print
                let typeNumber = 0;
                let errorCorrectionLevel = "L";
                let qr = qrcodeGenerator(typeNumber, errorCorrectionLevel);
                qr.addData(JSON.stringify(qrcode.content));
                qr.make();
                let qrcodeImg = qr.createImgTag(4);
                document.getElementById("sc-print-qrcode-placeholder").innerHTML = qrcodeImg;
            }, 500);
        },
        async createProductQrcode() {
            let params = {
                content: {
                    productId: this.selectedProduct,
                },
                qrcodeType: 2,
            };
            let response = await QrcodeAPICalls.createQrcode(params);
            console.log(response);
        },
        async createQuantityQrcode() {
            let params = {
                content: {
                    quantity: this.createQRQuantity,
                },
                qrcodeType: 3,
            };
            let response = await QrcodeAPICalls.createQrcode(params);
            console.log(response);
        },
        async getProducts() {
            let products = await ProductAPICalls.getProducts();
            this.productsList = products;
        },
        async getQrcodes() {
            let qrcodes = await QrcodeAPICalls.getQrcodes();
            this.qrcodesProduct = [];
            this.qrcodesQuantity = [];

            _.each(qrcodes, (qrcode) => {
                if (qrcode.qrcodeType && qrcode.qrcodeType.id === 2) {
                    let createdAtFormatted = moment(qrcode.created).format("ll");
                    qrcode.createdAtFormatted = createdAtFormatted;
                    this.qrcodesProduct.push(qrcode);
                } else if (qrcode.qrcodeType && qrcode.qrcodeType.id === 3) {
                    let createdAtFormatted = moment(qrcode.created).format("ll");
                    qrcode.createdAtFormatted = createdAtFormatted;
                    this.qrcodesQuantity.push(qrcode);
                }
            });
            this.generateQrcodes();
        },
        // Create
        async createQuickbooksAccessToken() {
            let config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                params: {
                    code: this.$route.query.code,
                },
            };
            console.log(config);
            axios
                .get(quickbooks_create_token_endpoint, config)
                .then((res) => {
                    console.log("Quickbooks Token", res.data);
                })
                .catch((err) => {
                    console.log("Quickbooks Token err", err);
                });
        },
        // Refresh
        async refreshAccessToken() {
            axios
                .get(quickbooks_refresh_token_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    this.$toasted.show("Refresh Token Success !", { position: "bottom-center", className: "sc-toast-success", duration: 5000 });
                    console.log("Refresh Access Token", res.data);
                })
                .catch((err) => {
                    console.log("Refresh Access Token", err);
                });
        },
        // Create new Invoice
        async createInvoice() {
            let customerId = "428"; // Santa Claus
            let params = {
                Line: [
                    {
                        DetailType: "SalesItemLineDetail",
                        Description: "Test Description",
                        Amount: "120",
                        SalesItemLineDetail: {
                            Qty: 2.5,
                            ItemRef: {
                                name: "BULK:BULK - Rock Salt Custom",
                                value: "134",
                            },
                            TaxCodeRef: {
                                value: "20", // HST ON
                            },
                        },
                    },
                ],
                CustomerRef: {
                    value: customerId,
                },
            };
            let response = await QuickbooksAPICalls.createInvoice(params);
            console.log(response);
        },
        // Get Invoice Info
        async getInvoiceInfo() {
            let invoiceId = 22543;
            let response = await QuickbooksAPICalls.getInvoice(invoiceId);
            console.log(response);
        },
        async getAllInvoices() {
            axios
                .get(quickbooks_invoice_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Get All Invoice:", res.data);
                })
                .catch((err) => {
                    console.log("Get All Invoice Error:", err);
                });
        },
        // Customer
        async getQuickbooksCustomer() {
            axios
                .get(quickbooks_get_customer_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Quickbooks Customer:", res.data);
                })
                .catch((err) => {
                    console.log("Quickbooks Customer Error:", err);
                });
        },
        // All Customers
        async getQuickbooksAllCustomers() {
            axios
                .get(quickbooks_get_all_customer_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Quickbooks Customers:", res.data);
                })
                .catch((err) => {
                    console.log("Quickbooks Customers Error:", err.response);
                });
        },
        async getQuickbooksAllProducts() {
            axios
                .get(quickbooks_product_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Quickbooks All Products:", JSON.stringify(res.data, null, 2));
                })
                .catch((err) => {
                    console.log("Quickbooks All Products Error:", err.response);
                });
        },
        // Url
        async quickbooksAuthUri() {
            axios
                .get(quickbooks_get_auth_uri_endpoint, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                })
                .then((res) => {
                    console.log("Auth Uri", res.data);
                    let url = res.data;
                    window.open(url, "_self");
                })
                .catch((err) => {
                    console.log("Auth Uri", err);
                });
        },
        async createAllPasswords() {
            // let maxIndex = 152;
            // for (let i = 1; i <= maxIndex; i++) {
            //     try {
            //         await QuickbooksAPICalls.createPassword(i);
            //     } catch (e) {
            //         console.log(e);
            //     }
            // }
        },
        async createCustomerPassword() {
            // let customerId = 126; // Triple L Lawn & Landscape ID
            // try {
            //     await QuickbooksAPICalls.createPassword(customerId);
            //     console.log("Success");
            // } catch (e) {
            //     console.log(e);
            // }
        },
        // Invoices
        async getQuickbooksInvoices() {
            let range = [1, 1001, 2001, 3001, 4001, 5001, 6001, 7001, 8001, 9001, 10001, 11001, 12001, 13001, 14001, 15001];
            // let range = [10001, 11001, 12001, 13001, 14001, 15001, 16001, 17001, 18001, 19001];
            // let range = [20001, 21001, 22001, 23001, 24001, 25001, 26001, 27001, 28001, 29001];
            // let range = [30001, 31001, 32001, 33001, 34001, 35001, 36001, 37001, 38001, 39001];
            // let range = [40001, 41001, 42001, 43001, 44001, 45001, 46001, 47001, 48001, 49001];
            // let range = [50001, 51001, 52001, 53001];

            for (let index in range) {
                try {
                    let startPosition = range[index];
                    console.log("Position:", startPosition);
                    let response = await APICalls.getInvoices(startPosition);
                    let invoiceRes = _.get(response, "QueryResponse.Invoice");

                    if (!invoiceRes) console.log(invoiceRes);
                    this.allInvoices = this.allInvoices.concat(invoiceRes);
                    console.log("RESULT", this.allInvoices.length)
                } catch (e) {
                    console.log(e);
                }
            }
            console.log(JSON.stringify(this.allInvoices, null, 2));
        },
        async printTicket() {
            console.log("Print Ticket");
        },
    },
    watch: {
        productsList: function(val) {
            this.productOptions = [];
            this.productOptions.push({ value: null, text: "Select product" });
            _.each(val, (v) => {
                let option = {};
                option.value = v.id;
                option.text = v.name;
                this.productOptions.push(option);
            });
        },
    },
    mounted() {
        // this.getQrcodes();
        // this.getProducts();

        // For creating new quantity qrcodes
        // this.$refs["sc-modal-quantity-qrcode"].show();

        let code = this.$route.query.code;
        if (code) {
            console.log("Code found - Create Access Token");
            this.createQuickbooksAccessToken();
        } else {
            console.log("Node code found");
        }
    },
};
</script>

<style lang="scss">
#sc-settings {
    .sc-button-primary {
        width: 250px;
        margin-top: 20px;
    }

    table {
        width: 100%;

        th {
            padding: 10px;
            text-align: center;
        }

        td {
            text-align: center;

            img {
                width: 100px;
                height: 100px;
            }
        }
    }

    #sc-settings-footer {
        position: fixed;
        bottom: 0;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        margin-left: -80px;
    }
}
</style>
