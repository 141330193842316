import axios from "axios";
import { quickbooks_get_invoices_endpoint } from "./../../constant/api-endpoints";

let getInvoices = function(startPosition) {
  return new Promise((resolve, reject) => {
    let config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
      params: {
        startPosition: startPosition,
      },
    };
    axios
      .get(quickbooks_get_invoices_endpoint, config)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
};

export default { getInvoices };
